/*  =============================================  */
/*  ==============  GLOBAL STYLES  ==============  */
/*  =============================================  */

html {
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    height: stretch;

    @supports (height: -moz-available) {
        height: 100vh;
    }
}

body {
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    min-height: 100vh;
    min-height: stretch;
    width: 100%;
    background-color: var(--background-color-level-current, var(--white));

    @supports (height: -moz-available) {
        height: 100vh;
    }

    /******
    * header footer forces the body to be display block and no one knows if it's still needed
    * this will force it to be flex and if we don't see any issues, we can request header/footer
    * to remove the display block rule
    ******/
    &[class][class][class] {
        display: flex !important;
    }

    &.force-scrollbar {
        scrollbar-color: var(--scrollbar) var(--scrollbar-background);
        scrollbar-width: 16px;

        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-thumb {
            border: 2.5px solid transparent;
            border-radius: 12px;
            background-color: var(--scrollbar);
            background-clip: content-box;

            &:hover {
                background-color: var(--scrollbar-hover);
            }
        }

        &::-webkit-scrollbar-track {
            background-color: var(--scrollbar-background);
            border: 1px solid var(--scrollbar-border);
        }
    }
}

td,
th {
    padding-inline-end: 1em;
    padding-block-end: 0.62em;
}
@layer preIPS {
    figure {
        margin: 0;
    }
}

article[data-aid-version] .page-break {
    height: 0;
    pointer-events: none;

    &::after {
        right: initial;
        inset-inline-end: 12px;
        line-height: 1;
        position: fixed;
        transform: translate(8px, -2.3em) rotate(-90deg);
        transform-origin: bottom right;

        @media (min-width: 46rem) {
            inset-inline-end: 22px;
            transform: translateY(-1.35em);
        }

        @nest p & {
            z-index: -1;
        }
    }
}

article[data-aid-version] .active-item,
article[data-aid-version] .bookmarkHighlight {
    &::before {
        content: "";
        height: calc(var(--height, 0) + 1rem);
        position: absolute;
        transform: translateY(-0.5rem);
        border-inline-start: 3px solid var(--accentColorPrimary);
        inset-inline-start: 1.25rem;

        @media (width >= 46em) {
            inset-inline-start: 3.25rem;
        }
    }
}

article[data-aid-version] .bookmarkHighlight::before {
    border-color: var(--borderColorTertiary);
}

article#main aside.alert.warning {
    font-family: var(--sans);
    position: relative;
    box-shadow: var(--boxShadowRaised);
    border: 1px solid var(--borderColorTertiary);
    border-radius: 2px;
    padding: var(--spacing16);
    margin-bottom: var(--spacing32);
    background-color: var(--backgroundColorLevel5);

    &::before {
        position: absolute;
        content: "";
        display: block;
        width: var(--spacing8);
        height: calc(100% + 2px);
        top: -1px;
        inset-inline-start: -1px;
        background: var(--accentColorWarning);
        border-start-start-radius: 2px;
        border-end-start-radius: 2px;
    }

    & svg {
        position: absolute;
    }

    & header {
        margin-bottom: 0;

        & .title {
            font-weight: 700;
            font-size: var(--fontSize18);
            line-height: var(--lineHeightTight);
            margin-bottom: var(--spacing16);
        }
    }

    & p {
        font-weight: 400;
        font-size: var(--fontSize16);
        line-height: var(--lineHeight);
        -webkit-margin-start: var(--spacing32);
        margin-inline-start: var(--spacing32);
        margin-bottom: var(--spacing16);
    }
}

@media print {
    /* The rules with "display: block !important;" are to fix a firefox bug where printing breaks on
    display flex/grid/inline-block https://stackoverflow.com/questions/4609007/firefox-printing-only-1st-page  */
    body[class][class][class] {
        display: block !important;
    }

    #app {
        display: block !important;

        & > div {
            display: block !important;
        }

        & main {
            display: block !important;
        }

        & main > div.reader {
            display: block !important;
        }

        & aside,
        & figure {
            page-break-inside: avoid;
        }

        & [data-aid-version] {
            & figure.image,
            & .no-print,
            & img {
                display: none;
            }

            & figure.diagram {
                display: block;

                & img {
                    display: inline-block;
                }
            }
        }
    }

    .print-show-footnotes:not(.print-hide-footnotes--override) #app,
    .print-show-footnotes--override #app {
        & span sup {
            display: inline;
        }

        & footer {
            &.notes {
                display: block;

                & li[data-full-marker] p {
                    padding-inline-start: 40px;
                }
            }

            &.study-notes {
                display: block;

                & li {
                    break-inside: auto;
                    page-break-inside: auto;
                }

                & li[data-full-marker] {
                    break-inside: avoid;
                    display: flex;
                    flex-wrap: nowrap;
                    page-break-inside: avoid;

                    &::before {
                        content: attr(data-full-marker);
                        flex: 0 0 40px;
                    }
                }
            }
        }
    }

    .print-hide-footnotes:not(.print-show-footnotes--override) #app,
    .print-hide-footnotes--override #app {
        & a sup {
            display: none;
        }
    }

    #app mark {
        /* for printing background color, aka highlights */
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .print-hide-highlights #app mark {
        background: none;
        box-shadow: none;
    }

    /* Removes Qualtrics button while printing */
    .QSIFeedbackButton {
        /* !important is used to overrule all third-party code from Qualtrics */
        display: none !important;
    }
}

/*  =============================================  */
/*  ==============  HEADER STYLES  ==============  */
/*  =============================================  */

h1,
body > header > .subtitle {
    inset-block-start: 0;
}

.title-number {
    inset-block-start: 7px;
}

@layer preIPS {
    h1 {
        font-family: var(--serif);
        position: unset;
    }
}

h1 > .dominant {
    font-size: var(--fontSize36);
}

.searchHighlight {
    background-color: yellow;
    outline: 2px solid yellow;
}

.theme-dark {
    .searchHighlight {
        color: var(--text-color-inverted);
    }
}

.theme-os {
    @media (prefers-color-scheme: dark) {
        .searchHighlight {
            color: var(--text-color-inverted);
        }
    }
}

/*  =============================================  */
/*  =============  APP LEVEL STYLES  ============  */
/*  =============================================  */

.app {
    min-height: 50vh;
    margin-block-end: -100px;
}

#app {
    flex: 1;
    display: flex;
    flex-direction: column;
}

#app article[data-aid-version] div.body,
#cross-link-overlay article[data-aid-version] div.body {
    --increment: 1;

    color: var(--textColorPrimary);
    font-weight: var(--fontWeightNormal);
    padding-inline: 0;

    font-size: var(--fontSize18);

    &.fontSansSerif {
        font-family: var(--sans);

        & header * {
            font-family: var(--sans);
        }
    }

    & a {
        color: var(--accentColorLink);
    }

    & .ActionBar a {
        color: var(--textColorPrimary);
    }

    .searchHighlight {
        & a {
            color: var(--searchHighlightAnchorColor);
        }
    }
}

@media print {
    #app article[data-aid-version] div.body,
    #app #cross-link-overlay article[data-aid-version] div.body {
        color: var(--black);

        & a {
            color: var(--accentColorLink);
        }
    }
}

#page {
    display: contents;
}

#portalRoot {
    z-index: 1400;
    position: relative;
}

:global(.platform-mask-blurred) #portalRoot {
    filter: blur(0);
}

.body-block {
    margin: 0;
}

article[data-aid-version] div.body {
    padding: var(--spacing64) 13.5%;
}

/*  =============================================  */
/*  =============  PLATFORM STYLES  =============  */
/*  =============================================  */

/*  ==================  FOOTER  =================  */
.LDSORGFooter {
    z-index: 600;
}

/*  ==================  GENERAL  =================  */
a.pf-popup__trigger {
    color: white;
}

platform-header .header-placeholder {
    min-height: 74px;
    border-bottom: 1px solid var(--grey5);
}

/*  ==============================================  */
/*  ==============  SPECIFIC STYLES  =============  */
/*  ==============================================  */

/*  ============  MANUALS BLOCK QUOTES  ============*/

[data-aid-version] blockquote > img {
    float: inline-start;
    margin-block-end: 15px;
    margin-inline: 1.6em 20px;
    padding: 0;
    width: 86px;
}

/*  =============  YOUTH CURRICULUM  =============  */
article[data-aid-version][data-uri*="/youth/learn/"][data-uri$="/overview"]
    .body-block {
    padding: 0;
}

/*  =============  _MANIFEST AND _CONTENTS STYLES  =============  */
article[data-aid-version][data-uri$="_manifest"],
article[data-aid-version][data-uri$="_contents"] {
    & header {
        margin-block-end: var(--spacing32);

        & .title {
            font-family: var(--sans);
            font-size: var(--fontSize42);
            color: var(--textColorPrimary);
        }

        & .subtitle {
            margin-block: var(--spacing32);
            font-weight: var(--fontWeightLight);
            color: var(--textColorPrimary);
        }

        & .deck {
            margin-block: var(--spacing16);
            color: var(--textColorPrimary);
        }
    }

    /* formal stylings for scriptures and conference */
    &[data-uri^="/scriptures"] header,
    &[data-uri^="/general-conference"] header {
        & .title {
            font-family: var(--serif);
            font-weight: var(--fontWeightNormal);
        }

        & .subtitle {
            font-family: var(--serif);
            font-weight: var(--fontWeightNormal);
        }
    }

    & ul {
        padding-inline-start: 0;
    }

    & .label {
        padding: var(--spacing32) 0;
    }
    & .label-tight {
        padding: var(--spacing16) 0;
    }

    & .letter-title {
        font-weight: 700;
    }

    & .primaryMeta,
    & .secondaryMeta {
        display: inline;
    }
}

/*  =============  THEME OVERIDE STYLES  =============  */
article[data-aid-version] {
    --proclamationColor: #5b1400;
    --familyProclamationColor: #005175;
    @media (prefers-color-scheme: dark) {
        --proclamationColor: var(--textColorPrimary);
        --familyProclamationColor: var(--textColorPrimary);
    }
    &.proclamations[data-uri="/scriptures/af-poster/1"] h1 .dominant,
    &.proclamations[class][class] h1,
    &.proclamations header > .subtitle,
    &.proclamations figure .title,
    &.proclamations .byline p,
    &.proclamations .epigraph {
        color: var(--proclamationColor);
    }
    &.proclamations[data-uri="/scriptures/the-family-a-proclamation-to-the-world/the-family-a-proclamation-to-the-world"]
        .body
        > header
        h1 {
        color: var(--familyProclamationColor);
    }
    &.proclamations[data-uri="/scriptures/af-poster/1"] .body > header,
    &.proclamations header > .subtitle {
        border-color: var(--borderColorSecondary);
    }
}

/* The `summary` and `details` below are copied from eden because the IPS CSS basically removes all of it so have to reapply it */
[data-render-instructions="collapsible"] summary {
    display: block;
    cursor: pointer;
    color: var(--accentColorLink);
    margin-block-end: 0px;
    transition: margin-block-end 80ms ease-out 0s;
    width: 100%;
    list-style: none;
    &[open] {
        text-decoration: none;
        color: var(--textColorPrimary);
        background: none;
        margin-block-end: 1.5rem;
        transition: margin-block-end 80ms ease-in 0s;
    }
    h2 {
        all: unset;
    }
}
[data-render-instructions="collapsible"] details {
    border-block-end: 1px solid var(--border-color-tertiary, #e0e2e2);
    padding: 1.5rem;
    padding-inline-end: 3rem;
    transition: padding-block-end 80ms ease-out 0s;
    &:not([open]):hover {
        text-decoration: underline 1px solid var(--accentColorLink);
        background: var(--background-color-hover, #eff0f0);
    }
}

[data-render-instructions="collapsible"] summary {
    /* Don't show the text as selected as we don't let users annotate this */
    user-select: none;
}
